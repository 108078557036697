// consumer_trains_common_queryOverweightBecause 获取超标枚举
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-staff-controller/detailUsingPOST
const consumer_trains_common_queryOverweightBecause = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/common/queryOverweightBecause',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_common_queryOverweightBecause;