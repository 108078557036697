// consumer_excessive_application 企业待/已支付单 (超标列表用)
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://192.168.0.21:8765/doc.html#/haolv-consumer/excessive-controller/excessiveApplicationListUsingPOST
const consumer_excessive_application = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/excessive/application',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_excessive_application;