import consumer_trains_common_queryOverweightBecause from '@/lib/data-service/haolv-default/consumer_trains_common_queryOverweightBecause'
import consumer_excessive_application from '@/lib/data-service/haolv-default/consumer_excessive_application'
import Pagination from '@/component/pagination/index.vue'
export default {
    data () {
        return {
            params: {
                applyNo: '',
                airTicketType: '全部',
                dateArr: [],
                currentPage: 1,
                pageSize: 10,
                endTime: '',
                startTime: '',
                type: 1,
                total: 0
            },
            options: [],
            list: [],
            layout: 'total, sizes, prev, pager, next'
        }
    },
    components: {
        Pagination
    },
    created () {},
    mounted () {},
    activated () {
        this._getReasonList();
        this.getExcessiveApplication();
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        changeOrderDate(val) {
            if (val === '' || val === null) {
                this.params.startTime = '';
                this.params.endTime = ''
            } else {
                this.params.startTime = val[0];
                this.params.endTime = val[1];
            }
        },
        _getReasonList() {
            const __this = this;
            consumer_trains_common_queryOverweightBecause().then(res => {
                __this.options = res.datas.flightResult;
                __this.options.unshift({code: '', msg: '全部'});
            })
        },
        getExcessiveApplication() {
            let currentParams = {
                applyNo: this.params.applyNo,
                airTicketType: this.params.airTicketType,
                dateArr: this.params.dateArr,
                currentPage: this.params.currentPage,
                pageSize: this.params.pageSize,
                endTime: this.params.endTime,
                startTime: this.params.startTime,
                type: this.params.type,
                total: this.params.total,
            }
            if (currentParams.airTicketType === '全部') { currentParams.airTicketType = '' }

            consumer_excessive_application(currentParams).then(res => {
                this.list = res.pageResult.pageData;
                this.params.total = res.pageResult.totalCount
            }).catch(() => {
                this.list = [];
                this.params.total = 0
            })
        },
        search() {
            this.params.currentPage = 1;
            this.getExcessiveApplication()
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/